import React, { useContext, useEffect, useState } from "react";
import "./AddJudgeForm.css";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Typography } from "@mui/material";
import useEdit from "../../../../Hooks/useEdit";
import useSave from "../../../../Hooks/useSave";
import { BaseURL, judgesURL } from "../../../../Hooks/URL";
import { Formik } from "formik";
import * as Yup from "yup";
import useFetch from "../../../../Hooks/useFetch";
import phone from "phone";
import { PhoneInput } from "react-international-phone";
import useFetchWhen from "../../../../Hooks/useFetchWhen";
import { userContext } from "../../../../Contexts/userContext";
import { images } from "../../../../Assets/assetsHelper";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";

const JudgeSchema = Yup.object().shape({
  name: Yup.string().required("Name required !!"),
  email: Yup.string().email("Invalid email !!").required("Email required !!"),
});

function AddJudgeForm({ setOpen, setJudges }) {
  const { contextData, setContextData } = useContext(userContext);
  const [newObject, setNewObject] = useState({});
  const [Phone, setPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(null);
  const [PhoneTouched, setPhoneTouched] = useState(false);
  const [goSave, setGoSave] = useState(false);
  const { saveState, loading: addLoading } = useSave({
    url: judgesURL,
    goSave,
    setGoSave,
    newObject,
    setNewData: {
      function: setJudges,
    },
  });

  useEffect(() => {
    if (!phone(Phone)?.isValid) setPhoneError("Valid Phone Number Required!!");
    else setPhoneError("");
  }, [Phone]);

  useEffect(() => {
    if (saveState.dataRes.status == "success") {
      setOpen(false);
    }
  }, [saveState]);

  return (
    <div className="SmallFormWrapper">
      <Formik
        initialValues={{
          name: "",
          email: "",
        }}
        validationSchema={JudgeSchema}
        onSubmit={(values) => {
          if (!PhoneError) {
            setNewObject({ contactNumber: Phone, ...values });
            setGoSave(true);
          } else {
            setPhoneTouched(true);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="ClassSessionFormContainer">
              <TextFieldInput
                fullWidth
                type="text"
                placeholder={"Enter Judge Name"}
                className="Input"
                label={"Judge Name"}
                name={"name"}
                change={handleChange}
                blur={handleBlur}
                newValue={values.name}
              />
              {errors.name && touched.name && errors.name && (
                <p className="error">
                  {errors.name && touched.name && errors.name}
                </p>
              )}

              <TextFieldInput
                fullWidth
                type="email"
                placeholder={"Enter Judge Email"}
                className="Input"
                label={"Judge Email"}
                name={"email"}
                change={handleChange}
                blur={handleBlur}
                newValue={values.email}
              />
              {errors.email && touched.email && errors.email && (
                <p className="error">
                  {errors.email && touched.email && errors.email}
                </p>
              )}

              <div
                style={{
                  position: "relative",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <label className="labelPhone">Phone Number*</label>
                <PhoneInput
                  label={"Enter Judge Phone Number"}
                  className="textfield Input phoneInput"
                  value={Phone}
                  defaultCountry="eg"
                  onChange={(e) => {
                    setPhone(e);
                  }}
                />
                {PhoneError && PhoneTouched ? (
                  <p className="error">{PhoneError}</p>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={"Add"}
                loading={addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddJudgeForm;
