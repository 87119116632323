import React, { useEffect, useState } from "react";
import "./AssignMissionToJudge.css";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { Autocomplete } from "@mui/material";
import useSave from "../../../../Hooks/useSave";
import { judgesURL } from "../../../../Hooks/URL";
import { Formik } from "formik";
import useFetch from "../../../../Hooks/useFetch";

function AssignMissionToJudge({ judge, url, setOpen, setMissions }) {
  const [newObject, setNewObject] = useState({});
  const [miss, setMiss] = useState([]);
  const [error, setError] = useState("");
  const [goSave, setGoSave] = useState(false);
  const { saveState, loading: addLoading } = useSave({
    url: url,
    goSave,
    setGoSave,
    newObject,
    setNewData: {
      function: setMissions,
    },
  });
  const {
    data: missionsList,
    loading: missionsLoading,
    error: missionsError,
  } = useFetch(`${judgesURL}/${judge?.id}/missions/toAssign`);

  useEffect(() => {
    if (saveState.dataRes.status == "success") {
      setOpen(false);
    }
  }, [saveState]);

  useEffect(() => {
    if (miss.length) {
      setError("");
    }
  }, [miss]);

  return (
    <div className="CourseSmallFormWrapper">
      <Formik
        initialValues={{
          missions: [],
        }}
        onSubmit={(values) => {
          if (miss.length) {
            setNewObject({ missionsIds: miss?.map((item) => item?.id) });
            setGoSave(true);
          } else {
            setError("Add at least one mission !!");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="ClassFormContainer">
              <br />

              <Autocomplete
                multiple
                options={missionsList?.data ?? []}
                getOptionLabel={(option) => `${option?.order} - ${option?.name}, ${option?.categoryName}, ${option?.ageGroupName}`}
                filterSelectedOptions
                name={"missions"}
                className="choose"
                value={miss}
                onChange={(e, newValue) => setMiss(newValue)}
                renderInput={(params) => (
                  <TextFieldInput
                    {...params}
                    label="Add Mission"
                    placeholder="Missions"
                    className={`Input`}
                    styles={{ width: "100%" }}
                    name={"missions"}
                    Required={false}
                  />
                )}
              />
              {error && <p className="error">{error}</p>}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={"Add"}
                loading={addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AssignMissionToJudge;
