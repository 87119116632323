import React, { useEffect, useState } from "react";
import "./AddMissionForm.css";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import useSave from "../../../../Hooks/useSave";
import { Formik } from "formik";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextArea from "../../../Atoms/Inputs/TextArea/TextArea";
import * as Yup from "yup";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import { DateTimePicker } from "@mui/x-date-pickers";

const sessionSchema = Yup.object().shape({
  name: Yup.string().required("Mission Name required !!"),
  // order: Yup.string().required("Mission Order required !!"),
});

function AddMissionForm({ Edit = false, url, setOpen, setMissions }) {
  const [newObject, setNewObject] = useState({});
  const [missionDate, setMissionsDate] = useState(null);
  const [goSave, setGoSave] = useState(false);
  const { saveState, loading: addLoading } = useSave({
    url: url,
    goSave,
    setGoSave,
    newObject,
    setNewData: {
      function: setMissions,
    },
  });

  if (saveState.dataRes.status == "success") {
    setOpen(false);
  }

  return (
    <div className="CourseSmallFormWrapper">
      <Formik
        validationSchema={sessionSchema}
        initialValues={{
          name: "",
          // order: "",
        }}
        onSubmit={(values) => {
          setNewObject({ ...values, dateTime: missionDate.add(1, "day") });
          setGoSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="ClassSessionFormContainer">
              <br />
              <TextFieldInput
                fullWidth
                type="text"
                placeholder={"Enter Mission Name"}
                className="Input"
                label={"Mission Name"}
                name={"name"}
                change={handleChange}
                blur={handleBlur}
                newValue={values.name}
              />
              {errors.name && touched.name && errors.name && (
                <p className="error">
                  {errors.name && touched.name && errors.name}
                </p>
              )}
              {/* <TextFieldInput
                fullWidth
                type="number"
                placeholder={"Enter Mission Order"}
                className="Input"
                label={"Mission Order"}
                name={"order"}
                change={handleChange}
                blur={handleBlur}
                newValue={values.order}
              />
              {errors.order && touched.order && errors.order && (
                <p className="error">
                  {errors.order && touched.order && errors.order}
                </p>
              )} */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DateTimePicker
                    label="Mission Date / Time"
                    value={missionDate}
                    onChange={(newValue) => {
                      setMissionsDate(newValue);
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={"Add"}
                loading={addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddMissionForm;
