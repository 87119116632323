import React, { useContext, useEffect, useState } from "react";
import "./AddAwardForm.css";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import useSave from "../../../../Hooks/useSave";
import {
  ageGroupsURL,
  BaseURL,
  categoriesURL,
  judgesURL,
} from "../../../../Hooks/URL";
import { Formik } from "formik";
import * as Yup from "yup";
import phone from "phone";
import { PhoneInput } from "react-international-phone";
import { userContext } from "../../../../Contexts/userContext";

const AwardSchema = Yup.object().shape({
  award: Yup.string().required("Award name required !!"),
});

function AddAwardForm({ setOpen, id, setAwards }) {
  const { contextData, setContextData } = useContext(userContext);
  const [newObject, setNewObject] = useState({});
  const [goSave, setGoSave] = useState(false);
  const { saveState, loading: addLoading } = useSave({
    url: `${ageGroupsURL}/${id}/specialAwards`,
    goSave,
    setGoSave,
    newObject,
    setNewData: {
      function: setAwards,
    },
  });

  useEffect(() => {
    if (saveState.dataRes.status == "success") {
      setOpen(false);
    }
  }, [saveState]);

  return (
    <div className="SmallFormWrapper">
      <Formik
        initialValues={{
          award: "",
        }}
        validationSchema={AwardSchema}
        onSubmit={(values) => {
          setNewObject(values);
          setGoSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="ClassSessionFormContainer">
              <TextFieldInput
                fullWidth
                type="text"
                placeholder={"Enter Award Name"}
                className="Input"
                label={"Award Name"}
                name={"award"}
                change={handleChange}
                blur={handleBlur}
                newValue={values.award}
              />
              {errors.award && touched.award && errors.award && (
                <p className="error">
                  {errors.award && touched.award && errors.award}
                </p>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={"Add"}
                loading={addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddAwardForm;
