import React, { useEffect, useState } from "react";
import "./AddReportAttributesForm.css";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { Box, IconButton, Typography } from "@mui/material";
import useSave from "../../../../Hooks/useSave";
import AddIcon from "@mui/icons-material/Add";
import { Formik } from "formik";
import * as Yup from "yup";
import { ageGroupsURL } from "../../../../Hooks/URL";

// const CourseSchema = Yup.object().shape({
//   title: Yup.string().required("Program name required !!"),
//   description: Yup.string().required("Description required !!"),
// });

const GroupAttributes = ({ group, index, attributes, setAttributes }) => {
  const [attributeName, setAttributesName] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [lastID, setLastId] = useState(1);
  const handleAddAttribute = () => {
    setAttributes(
      [
        ...attributes?.filter((item) => item?.number != group?.number),
        {
          ...attributes?.find((item) => item?.number == group?.number),
          attributes: [
            ...attributes?.find((item) => item?.number == group?.number)
              ?.attributes,
            {
              number: lastID,
              attributeName: attributeName,
              maxValue: maxValue,
            },
          ],
        },
      ].sort((a, b) => a.number - b.number)
    );
    setAttributesName("");
    setLastId(lastID + 1);
    setMaxValue("");
  };
  const handleDeleteAttribute = (attributeName) => {
    setAttributes(
      [
        ...attributes?.filter((item) => item?.number != group?.number),
        {
          ...attributes?.find((item) => item?.number == group?.number),
          attributes: [
            ...attributes
              ?.find((item) => item?.number == group?.number)
              ?.attributes?.filter(
                (att) => att?.attributeName != attributeName
              ),
          ],
        },
      ].sort((a, b) => a.number - b.number)
    );
  };
  return (
    <div
      key={index}
      style={{
        padding: "15px",
        borderRadius: "4px",
        background: "#efefef",
        color: "black",
        position: "relative",
        marginBottom: "5px",
        width: "100%",
      }}
    >
      Group {index + 1}: {group?.groupName}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <TextFieldInput
          Required={false}
          fullWidth
          type="text"
          placeholder={"Enter Attribute Name"}
          className="Input"
          name={"attributeName"}
          newValue={attributeName}
          change={(e) => {
            setAttributesName(e.target.value);
          }}
        />
        <TextFieldInput
          Required={false}
          fullWidth
          type="number"
          placeholder={"Enter Attribute Max Value"}
          className="Input"
          name={"maxValue"}
          newValue={maxValue}
          change={(e) => {
            setMaxValue(e.target.value);
          }}
        />
        <IconButton
          sx={{ height: "fit-content" }}
          onClick={() => {
            handleAddAttribute();
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <IconButton
        sx={{
          cursor: "pointer",
          position: "absolute",
          right: "15px",
          top: "10px",
          color: "#757575",
          fontSize: "17px",
          width: "30px",
          height: "30px",
        }}
        onClick={() => {
          setAttributes(
            attributes?.filter((item) => item?.groupName != group?.groupName)
          );
        }}
      >
        x
      </IconButton>
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        {attributes
          ?.find((item) => item?.groupName == group?.groupName)
          ?.attributes?.map((att) => (
            <div
              style={{
                padding: "25px 15px 15px",
                backgroundColor: "#6a0505",
                color: "white",
                borderRadius: "4px",
                width: "fit-content",
                position: "relative",
              }}
            >
              <IconButton
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "5px",
                  top: "6px",
                  color: "white",
                  fontSize: "15px",
                  width: "10px",
                  height: "10px",
                }}
                onClick={() => {
                  handleDeleteAttribute(att?.attributeName);
                }}
              >
                x
              </IconButton>
              {att?.attributeName} - {att?.maxValue}
            </div>
          ))}
      </div>
    </div>
  );
};

function AddReportAttributesForm({ ageGroupId, setOpen }) {
  const [newObject, setNewObject] = useState({});
  const [lastID, setLastID] = useState(1);
  const [attributes, setAttributes] = useState([]);
  const [goSave, setGoSave] = useState(false);
  const { saveState, loading: addLoading } = useSave({
    url: `${ageGroupsURL}/${ageGroupId}/report`,
    goSave,
    setGoSave,
    newObject,
    refreshAfter: true,
  });

  useEffect(() => {
    if (saveState.dataRes.status == "success") {
      setOpen(false);
    }
  }, [saveState]);

  const handleAddReportAttributeGroup = (value, setValue) => {
    if (value) {
      setAttributes([
        ...attributes,
        { number: lastID, groupName: value, attributes: [] },
      ]);
      setLastID(lastID + 1);
      setValue("");
    }
  };

  return (
    <div className="CourseSmallFormWrapper">
      <Formik
        initialValues={{}}
        onSubmit={(values) => {
          setNewObject({ groups: attributes });
          setGoSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="ReportFormContainer">
              <TextFieldInput
                Required={false}
                fullWidth
                type="text"
                setAttributes={setAttributes}
                attribute={attributes || []}
                EndAdornment={<AddIcon />}
                placeholder={"Enter Group Title"}
                className="Input"
                label={"Group Title"}
                name={"group"}
                handleAddReportAttribute={handleAddReportAttributeGroup}
              />
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                {attributes?.map((group, index) => (
                  <GroupAttributes
                    group={group}
                    index={index}
                    attributes={attributes}
                    setAttributes={setAttributes}
                  />
                ))}
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={"Add"}
                loading={addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddReportAttributesForm;
