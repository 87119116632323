import { images } from "../../../../Assets/assetsHelper";
import "./CategoryDetails.css";
import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router";
import { display } from "@mui/system";
import { Box } from "@mui/material";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import AddAwardForm from "../../../../Components/Organisms/Forms/AddAwardForm/AddAwardForm";
import FormModal from "../../../../Components/Molecules/FormModal/FormModal";

function CategoryDetails({
  loading,
  category,
  ageGroups,
  awards,
  Open,
  setOpen,
  setAwards,
}) {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      {/* <FormModal
        Open={Open}
        HandleClose={() => {
          setOpen(false);
        }}
        Form={
          <AddAwardForm
            Open={Open}
            setOpen={setOpen}
            id={category?.id}
            setAwards={setAwards}
          />
        }
        Title={"Add New Award"}
      /> */}
      {category && !loading ? (
        <div className="Cont">
          <h2 style={{ fontSize: "30px", margin: "0 0 10px 20px" }}>
            Category Name: {category.name}
          </h2>
          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h2 style={{ margin: "0 0 0 20px" }}>Special Awards:</h2>
            <PrimaryButton
              classBtn={"center"}
              text={"Add New Award"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "40px",
                padding: "0 15px",
                minWidth: "100px",
                textWrap: "noWrap",
              }}
              click={() => setOpen(true)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              flexWrap: "wrap",
              cursor: "pointer",
            }}
          >
            {awards?.map((item) => (
              <Box
                className="greyBoxWhite"
                sx={{
                  marginRight: { xs: "0", md: "20px" },
                  width: { xs: "100%", md: "auto" },
                }}
              >
                <div className="greyNumberBox">
                  <div>
                    <h4>Award's Name: </h4>
                    <h3>{item?.award}</h3>
                  </div>
                </div>
              </Box>
            ))}
          </Box> */}
          <h2 style={{ margin: "0 0 20px 20px" }}>Age Groups:</h2>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-between" },
              flexWrap: "wrap",
              cursor: "pointer",
            }}
          >
            {ageGroups?.map((item) => (
              <Box
                className="greyBoxWhite"
                sx={{ width: { xs: "100%", md: "49%", lg: "32%" } }}
              >
                <div className="greyNumberBox">
                  <div>
                    <h4>Age Group Name: </h4>
                    <h2>{item?.name}</h2>
                  </div>
                </div>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="greyNumberBox" style={{ width: "50%" }}>
                    <div>
                      <h4>Min Birthday: </h4>
                      <h2>{item?.minBirthday?.split("T")?.at(0)}</h2>
                    </div>
                  </div>
                  <div className="greyNumberBox" style={{ width: "50%" }}>
                    <div>
                      <h4>Max Birthday: </h4>
                      <h2>{item?.maxBirthday?.split("T")?.at(0)}</h2>
                    </div>
                  </div>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="greyNumberBox" style={{ width: "50%" }}>
                    <div>
                      <h4>Min Age: </h4>
                      <h2>{item?.minAge}</h2>
                    </div>
                  </div>
                  <div className="greyNumberBox" style={{ width: "50%" }}>
                    <div>
                      <h4>Max Age: </h4>
                      <h2>{item?.maxAge}</h2>
                    </div>
                  </div>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <PrimaryButton
                    classBtn={"center"}
                    text={"see more"}
                    style={{
                      textTransform: "capitalize",
                      borderRadius: "5px",
                      color: "var(--text-secondary)",
                      backgroundColor: "var(--primary)",
                      height: "35px",
                      padding: "0 10px",
                      minWidth: "100px",
                      textWrap: "noWrap",
                      marginTop: "15px",
                    }}
                    click={() =>
                      navigate(
                        `/categories/${item?.categoryId}/ageGroups/${item?.id}`
                      )
                    }
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </div>
      ) : (
        <div className="loadingBox">
          <BarLoader color="#6a0505" />
        </div>
      )}
    </div>
  );
}

export default CategoryDetails;
