import axios from "axios";
import { useReducer, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const initialState = {
  data: [],
  loading: true,
  error: null,
};

const ACTION = {
  API_REQUEST: "api-request",
  FETCH_DATA: "fetch-data",
  ERROR: "error",
};

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTION.API_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.FETCH_DATA:
      return { ...state, data: payload, loading: false };
    case ACTION.ERROR:
      return {
        ...state,
        loading: false,
        error: payload.err,
        status: payload.status,
      };
    default:
      return state;
  }
}

function useFetch(
  url,
  page = 1,
  limit,
  search,
  dataEdited,
  withSearchOnly = false,
  query
) {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["TOKEN"]);
  const [state, dispatch] = useReducer(reducer, initialState);
  let URL = "";
  if (limit) {
    URL = `${url}?page=${page + 1}&limit=${limit}${
      search ? `&search=${search}` : ""
    }${query ?? ""}`;
  } else {
    URL = url;
  }

  useEffect(() => {
    if ((withSearchOnly && search) || !withSearchOnly)
      if (!dataEdited && url) {
        dispatch({ type: ACTION.API_REQUEST });
        axios(URL, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/app.js",
            // Authorization: `Bearer ${cookies.TOKEN}`,
          },
          withCredentials: true,
        })
          .then((res) => {
            dispatch({ type: ACTION.FETCH_DATA, payload: res.data });
          })
          .catch((err) => {
            dispatch({
              type: ACTION.ERROR,
              payload: {
                err: err?.response?.data,
                status: err?.response?.data?.status,
              },
            });
            if (err?.response?.status == 401) {
              navigate("/login");
              localStorage.setItem("isLogged", false);
            } else {
              toast.error(err.response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      }
  }, [URL, dataEdited, search]);
  // dataEdited = false;
  return state;
}

export default useFetch;
