import "./JudgeProfile.css";
import React, { useState } from "react";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../../Components/Molecules/FormModal/FormModal";
import DeleteForm from "../../../../Components/Molecules/DeleteForm/DeleteForm";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { BarLoader } from "react-spinners";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import AssignTeamToJudge from "../../../../Components/Organisms/Forms/AssignTeamToJudge/AssignTeamToJudge";
import AssignMissionToJudge from "../../../../Components/Organisms/Forms/AssignMissionToJudge/AssignMissionToJudge";
import { missionsURL } from "../../../../Hooks/URL";
import { minWidth } from "@mui/system";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:first-child": {
    borderRadius: "5px 0 0 0",
  },
  "&:last-child": {
    borderRadius: "0 5px 0 0",
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6a0505",
    color: theme.palette.common.white,
    fontSize: "15px",
  },
}));

const columns = [
  {
    id: "teamId",
    label: "ID",
    minWidth: 50,
  },
  {
    id: "name",
    label: "Team Name",
    minWidth: 100,
  },
  { id: "remove", label: "", width: "100px" },
];

function JudgeProfile({
  judge,
  teams,
  setTeams,
  teamsLoading,
  loading,
  missions,
  setGoMission,
  missionsLoading,
  setOpenMission,
  OpenMission,
  setOpen,
  Open,
  value,
  handleChange,
  setMissions,
  missionsUrl,
  setGoTeam,
  setGo,
  setDeletedTeam,
}) {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      <FormModal
        Open={OpenMission}
        HandleClose={() => setOpenMission(false)}
        Form={
          <AssignMissionToJudge
            setOpen={setOpenMission}
            setMissions={setMissions}
            judge={judge}
            url={missionsUrl}
          />
        }
        Title={"Assign New Mission"}
        CustomStyle={"small"}
      />
      <FormModal
        Open={Open}
        HandleClose={() => setOpen(false)}
        Form={
          <AssignTeamToJudge
            setOpen={setOpen}
            setTeams={setTeams}
            judge={judge}
            missionJudgingId={
              missions?.find((item, i) => i == value)?.missionJudgingId
            }
          />
        }
        Title={"Assign New Team"}
        CustomStyle={"small"}
      />
      {judge && !loading ? (
        <>
          <div className="Cont">
            <div className="greyBox">
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: { xs: "column", md: "row" },
                  marginBottom: { xs: "10px", md: "0" },
                }}
              >
                <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                  <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                    <div>
                      <h4>Judge Name: </h4>
                      <h2>{judge?.name}</h2>
                    </div>
                  </div>
                  <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                    <div>
                      <h4>Email: </h4>
                      <h2>{judge?.email}</h2>
                    </div>
                  </div>
                  <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                    <div>
                      <h4>Phone Number: </h4>
                      <h2>{judge?.contactNumber}</h2>
                    </div>
                  </div>
                </Box>
                <DeleteForm
                  setGo={setGo}
                  title={"Are you sure to delete this judge ?"}
                  text={"Delete Judge"}
                  margin="0"
                  padding="0 20px"
                />
              </Box>
            </div>
          </div>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h2 style={{}}>Missions:</h2>
            <PrimaryButton
              classBtn={"center"}
              text={"Assgin New Mission"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "40px",
                padding: "0 15px",
                minWidth: "100px",
                textWrap: "noWrap",
              }}
              click={() => setOpenMission(true)}
            />
          </Box>
          {missions?.length && !missionsLoading ? (
            <>
              <Tabs value={value} onChange={handleChange}>
                {missions?.map((item) => (
                  <Tab label={`Mission ${item?.order}`} />
                ))}
              </Tabs>
              {missions?.map((item, i) => (
                <div
                  hidden={value !== i}
                  style={{ marginTop: "20px", padding: "10px" }}
                >
                  {value === i && (
                    <>
                      <div className="greyBoxWhite">
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <div className="greyNumberBox">
                              <div>
                                <h4>Mission Name: </h4>
                                <h3>{item?.name}</h3>
                              </div>
                            </div>
                            <div className="greyNumberBox">
                              <div>
                                <h4>Mission Order: </h4>
                                <h3>{item?.order}</h3>
                              </div>
                            </div>
                            {/* <div className="greyNumberBox">
                              <div>
                                <h4>Date / Time: </h4>
                                <h3>{item?.dateTime}</h3>
                              </div>
                            </div> */}
                            <div className="greyNumberBox">
                              <div>
                                <h4>Mission Status: </h4>
                                <h3>{item?.status}</h3>
                              </div>
                            </div>
                            <div className="greyNumberBox">
                              <div>
                                <h4>Completed: </h4>
                                <h3>
                                  {item?.missionJudgingCompleted
                                    ? "Completed"
                                    : "Not completed"}
                                </h3>
                              </div>
                            </div>
                            <div className="greyNumberBox">
                              <div>
                                <h4>Completion Date: </h4>
                                <h3>
                                  {item?.missionJudgingCompletionDate ?? "-"}
                                </h3>
                              </div>
                            </div>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <PrimaryButton
                              classBtn={"center"}
                              text={"see more"}
                              style={{
                                textTransform: "capitalize",
                                borderRadius: "5px",
                                color: "var(--text-secondary)",
                                backgroundColor: "var(--primary)",
                                height: "35px",
                                padding: "0 10px",
                                minWidth: "100px",
                                textWrap: "noWrap",
                                marginTop: "15px",
                              }}
                              click={() => navigate(`/missions/${item?.id}`)}
                            />
                            <DeleteForm
                              setGo={setGoMission}
                              title={"Are you sure to remove this mission ?"}
                              smallButton
                              text={"Remove Mission"}
                            />
                          </Box>
                        </Box>
                      </div>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <h2 style={{}}>Teams:</h2>
                        <PrimaryButton
                          classBtn={"center"}
                          text={"Assgin New Team"}
                          style={{
                            textTransform: "capitalize",
                            borderRadius: "5px",
                            color: "var(--text-secondary)",
                            backgroundColor: "var(--primary)",
                            height: "40px",
                            padding: "0 15px",
                            minWidth: "100px",
                            textWrap: "noWrap",
                          }}
                          click={() => setOpen(true)}
                        />
                      </Box>
                      <Paper
                        className="tableCont"
                        sx={{ width: "fit-content", minWidth: "100%" }}
                      >
                        {teams && !teamsLoading ? (
                          <>
                            <TableContainer>
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    {columns.map((column) => (
                                      <StyledTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                          minWidth: column?.minWidth,
                                          maxWidth: column?.maxWidth,
                                          width: column?.width,
                                        }}
                                      >
                                        {column.label}
                                      </StyledTableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {teams?.map((team) => {
                                    return (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={team.id}
                                      >
                                        {columns.map((column) => {
                                          const value = team[column.id];
                                          return (
                                            <TableCell
                                              key={column.id}
                                              align={column.align}
                                              style={{ padding: "10px" }}
                                            >
                                              {column.id == "remove" ? (
                                                <DeleteForm
                                                  setData={() =>
                                                    setDeletedTeam(team?.id)
                                                  }
                                                  setGo={setGoTeam}
                                                  title={
                                                    "Are you sure to remove this team ?"
                                                  }
                                                  smallButton
                                                  text={"Remove Team"}
                                                  padding="0 15px"
                                                  margin="0"
                                                />
                                              ) : (
                                                <span
                                                  onClick={() => {
                                                    navigate(
                                                      `/teams/${team.id}`
                                                    );
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {value}
                                                </span>
                                              )}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                              {!teams?.length ? (
                                <h3
                                  style={{
                                    textAlign: "center",
                                    margin: "50px",
                                  }}
                                >
                                  No teams Assigned Yet!!
                                </h3>
                              ) : (
                                ""
                              )}
                            </TableContainer>
                          </>
                        ) : (
                          <div className="loadingBox">
                            <BarLoader color="#6a0505" />
                          </div>
                        )}
                      </Paper>
                    </>
                  )}
                </div>
              ))}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {/* {missions?.map((item) => (
                  <div className="greyBoxWhite">
                    <div className="greyNumberBox">
                      <div>
                        <h4>Mission Name: </h4>
                        <h2>{item?.name}</h2>
                      </div>
                    </div>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="greyNumberBox">
                        <div>
                          <h4>Mission Order: </h4>
                          <h3>{item?.order}</h3>
                        </div>
                      </div>
                      <div className="greyNumberBox">
                        <div>
                          <h4>Date / Time: </h4>
                          <h3>{item?.dateTime}</h3>
                        </div>
                      </div>
                    </Box>

                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <PrimaryButton
                        classBtn={"center"}
                        text={"see more"}
                        style={{
                          textTransform: "capitalize",
                          borderRadius: "5px",
                          color: "var(--text-secondary)",
                          backgroundColor: "var(--primary)",
                          height: "35px",
                          padding: "0 10px",
                          minWidth: "100px",
                          textWrap: "noWrap",
                          marginTop: "15px",
                        }}
                        click={() => navigate(`/missions/${item?.id}`)}
                      />
                      <DeleteForm
                        setGo={setGoMission}
                        title={"Are you sure to delete this mission ?"}
                        smallButton
                      />
                    </Box>
                  </div>
                ))} */}
              </Box>
            </>
          ) : (
            <div>
              <h4 style={{ textAlign: "center" }}>
                No Missions Assigned Yet..
              </h4>
            </div>
          )}
        </>
      ) : (
        <div className="loadingBox">
          <BarLoader color="#6a0505" />
        </div>
      )}
    </div>
  );
}

export default JudgeProfile;
