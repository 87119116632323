import React, { useContext, useEffect, useState } from "react";
import { applicationsURL, teacherURL, teamsURL } from "../Hooks/URL";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";
import TeamsDetails from "../Templates/Home/Teams/TeamsDetails/TeamsDetails";

function TeamsDetailsPage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id } = useParams();

  const url = `${teamsURL}/${id}`;
  const missionUrl = `${teamsURL}/${id}/missions`;
  const awardsUrl = `${teamsURL}/${id}/specialAwards`;

  const [team, setTeam] = useState({});
  const [missions, setMissions] = useState([]);
  const [awards, setAwards] = useState([]);
  const { data, loading, error } = useFetch(url);
  const {
    data: missionsData,
    loading: missionsLoading,
    error: missionsError,
  } = useFetch(missionUrl);
  const {
    data: awardsData,
    loading: awardsLoading,
    error: awardsError,
  } = useFetch(awardsUrl);

  const [go, setGo] = useState(false);
  const { deleteRes } = useDelete(url, go, `teams`);

  useEffect(() => {
    if (!loading && !error && data) {
      setTeam(data.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!missionsLoading && !missionsError && missionsData) {
      setMissions(missionsData.data);
    }
  }, [missionsData, missionsLoading, missionsError]);

  useEffect(() => {
    if (!awardsLoading && !awardsError && awardsData) {
      setAwards(awardsData.data);
    }
  }, [awardsData, awardsLoading, awardsError]);

  return (
    <TeamsDetails
      url={url}
      loading={loading}
      role={contextData?.role}
      team={team}
      setGo={setGo}
      missions={missions}
      missionsLoading={missionsLoading}
      awards={awards}
      awardsLoading={awardsLoading}
    />
  );
}

export default TeamsDetailsPage;
