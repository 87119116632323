import { Box } from "@mui/material";
import { images } from "../../../../Assets/assetsHelper";
import "./TeamsDetails.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import DeleteForm from "../../../../Components/Molecules/DeleteForm/DeleteForm";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--primary)",
    color: theme.palette.common.white,
    padding: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TeamsDetails({
  team,
  loading,
  setGo,
  missions,
  missionsLoading,
  awards,
  awardsLoading,
}) {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      {team && !loading ? (
        <div className="TeacherCont">
          <div className="greyBox">
            <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Team Name: </h4>
                  <h3>{team?.name}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Team Id: </h4>
                  <h3>{team?.id}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Team Code: </h4>
                  <h3>{team?.teamId}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Organization: </h4>
                  <h3>{team?.application?.organizationName}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Organization Email: </h4>
                  <h3>{team?.application?.organizationEmail}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Organization Phone Number: </h4>
                  <h3>{team?.application?.organizationContactNumber}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Category: </h4>
                  <h3>{team?.ageGroupName}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Age Group: </h4>
                  <h3>{team?.ageGroupName}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Coach Name: </h4>
                  <h3>{team?.coach?.name}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Coach Email: </h4>
                  <h3>{team?.coach?.email}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Coach Phone Number: </h4>
                  <h3>{team?.coach?.contactNumber}</h3>
                </div>
              </div>
            </Box>
            <div style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "column" },
                  width: "100%",
                }}
              >
                <h2 style={{ marginTop: "20px" }}>Team Members:</h2>
                <TableContainer
                  component={Paper}
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">name</StyledTableCell>
                        <StyledTableCell align="center">
                          Birthday
                        </StyledTableCell>
                        <StyledTableCell align="center">Age</StyledTableCell>
                        <StyledTableCell align="center">
                          National ID
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {team?.students?.map((stu) => (
                        <StyledTableRow key={stu.name}>
                          <StyledTableCell align="center">
                            {stu.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {stu?.birthday?.split("T")?.at(0)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {stu?.age}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {stu?.nationalId}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </div>
          </div>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h2>Missions:</h2>
          </Box>
          {missions?.length && !missionsLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {missions?.map((item) => (
                <Box
                  className="greyBoxWhite"
                  sx={{ width: { xs: "100%", md: "32.8%" } }}
                >
                  <div className="greyNumberBox">
                    <div>
                      <h4>Mission Name: </h4>
                      <h3>{item?.name}</h3>
                    </div>
                  </div>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="greyNumberBox">
                      <div>
                        <h4>Mission Order: </h4>
                        <h3>{item?.order}</h3>
                      </div>
                    </div>
                    <div className="greyNumberBox">
                      <div>
                        <h4>Judge: </h4>
                        <h3
                          style={{
                            cursor: item?.judge?.id ? "pointer" : "auto",
                            textDecoration: item?.judge?.name
                              ? "underline"
                              : "none",
                          }}
                          onClick={() => {
                            if (item?.judge?.id)
                              navigate(`/judges/${item?.judge?.id}`);
                          }}
                        >
                          {item?.judge?.name ?? "-"}
                        </h3>
                      </div>
                    </div>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <PrimaryButton
                      classBtn={"center"}
                      text={"see more"}
                      style={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        color: "var(--text-secondary)",
                        backgroundColor: "var(--primary)",
                        height: "35px",
                        padding: "0 10px",
                        minWidth: "100px",
                        textWrap: "noWrap",
                        margin: "15px 5px 0",
                      }}
                      click={() => navigate(`/missions/${item?.id}`)}
                    />
                    {item?.scoringReportId ? (
                      <PrimaryButton
                        classBtn={"center"}
                        text={"scoring report"}
                        style={{
                          textTransform: "capitalize",
                          borderRadius: "5px",
                          color: "var(--text-secondary)",
                          backgroundColor: "var(--primary)",
                          height: "35px",
                          padding: "0 10px",
                          minWidth: "100px",
                          textWrap: "noWrap",
                          margin: "15px 5px 0",
                        }}
                        click={() =>
                          navigate(`/reports/${item?.scoringReportId}`)
                        }
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <div>
              <h4 style={{ textAlign: "center" }}>No Missions Added Yet..</h4>
            </div>
          )}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            <h2>Special Awards Ranking:</h2>
          </Box>
          {awards?.length && !awardsLoading ? (
            <>
              <TableContainer
                component={Paper}
                sx={{
                  margin: "20px 0",
                }}
              >
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">
                        Award Name
                      </StyledTableCell>
                      <StyledTableCell align="center">Score</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {awards?.map((team, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell align="center">
                          {team?.award}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {team?.score}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <div className="loadingBox">
              <BarLoader color="#6a0505" />
            </div>
          )}
        </div>
      ) : (
        <div className="loadingBox">
          <BarLoader color="#6a0505" />
        </div>
      )}
    </div>
  );
}

export default TeamsDetails;
