import { images } from "../../../../Assets/assetsHelper";
import "./AgeGroupDetails.css";
import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router";
import { display } from "@mui/system";
import { Autocomplete, Box, Typography } from "@mui/material";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import AddReportAttributesForm from "../../../../Components/Organisms/Forms/AddReportAttributesForm/AddReportAttributesForm";
import FormModal from "../../../../Components/Molecules/FormModal/FormModal";
import AddMissionForm from "../../../../Components/Organisms/Forms/AddMissionForm/AddMissionForm";
import DeleteForm from "../../../../Components/Molecules/DeleteForm/DeleteForm";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import Paper from "@mui/material/Paper";
import TextFieldInput from "../../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import AddAwardForm from "../../../../Components/Organisms/Forms/AddAwardForm/AddAwardForm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function AgeGroupDetails({
  loading,
  category,
  missions,
  missionsLoading,
  attributes,
  attributesLoading,
  setAttributes,
  setGo,
  setGoMission,
  Open,
  setOpen,
  OpenMission,
  setOpenMission,
  setMissions,
  missionsUrl,
  ranking,
  rankingLoading,
  setSelectedRank,
  selectedRank,
  awards,
  setAwards,
  OpenAward,
  setOpenAward,
  setGoSave,
  OpenGenetare,
  setOpenGenerate,
  addLoading,
}) {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      <FormModal
        Open={OpenGenetare}
        HandleClose={() => setOpenGenerate(false)}
        Form={""}
        Title={"All Final Reports Generated Successfully!"}
        XButton={true}
      />
      <FormModal
        Open={Open}
        HandleClose={() => setOpen(false)}
        Form={
          <AddReportAttributesForm
            ageGroupId={category?.id}
            setOpen={setOpen}
          />
        }
        Title={"Add Report Attributes"}
      />
      <FormModal
        Open={OpenMission}
        HandleClose={() => setOpenMission(false)}
        Form={
          <AddMissionForm
            setOpen={setOpenMission}
            url={missionsUrl}
            setMissions={setMissions}
          />
        }
        Title={"Add New Mission"}
        CustomStyle={"small"}
      />
      <FormModal
        Open={OpenAward}
        HandleClose={() => {
          setOpenAward(false);
        }}
        Form={
          <AddAwardForm
            Open={OpenAward}
            setOpen={setOpenAward}
            id={category?.id}
            setAwards={setAwards}
          />
        }
        Title={"Add New Award"}
      />
      {category && !loading ? (
        <div className="Cont">
          <div className="greyBox">
            <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Age Group Name: </h4>
                  <h3>{category?.name}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Min Birthday: </h4>
                  <h3>{category?.minBirthday?.split("T")?.at(0)}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Max Birthday: </h4>
                  <h3>{category?.maxBirthday?.split("T")?.at(0)}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Min Age: </h4>
                  <h3>{category?.minAge}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Max Age: </h4>
                  <h3>{category?.maxAge}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Number of Winners: </h4>
                  <h3>{category?.numberOfWinners}</h3>
                </div>
              </div>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              {category?.canGeneratefinalReports ? (
                <PrimaryButton
                  classBtn={"center"}
                  disabled={category?.finalReportsGenerated}
                  text={
                    !addLoading ? (
                      "Generate Final Reports"
                    ) : (
                      <div className="loadingBox">
                        <BarLoader color="#fff" />
                      </div>
                    )
                  }
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "5px",
                    color: "var(--text-secondary)",
                    backgroundColor: !category?.finalReportsGenerated
                      ? "var(--primary)"
                      : "#6a050550",
                    height: "40px",
                    padding: "0 15px",
                    minWidth: "100px",
                    textWrap: "noWrap",
                  }}
                  click={() => setGoSave(true)}
                />
              ) : (
                ""
              )}
            </Box>
          </div>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h2 style={{}}>Missions:</h2>
            <PrimaryButton
              classBtn={"center"}
              text={"Add New Mission"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "40px",
                padding: "0 15px",
                minWidth: "100px",
                textWrap: "noWrap",
              }}
              click={() => setOpenMission(true)}
            />
          </Box>
          {missions?.length && !missionsLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {missions?.map((item) => (
                <Box
                  className="greyBoxWhite"
                  sx={{ width: { xs: "100%", md: "32.8%" } }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      className="greyNumberBox"
                      style={{ minWidth: "200px" }}
                    >
                      <div>
                        <h4>Mission Name: </h4>
                        <h3>{item?.name}</h3>
                      </div>
                    </div>
                    <div
                      className="greyNumberBox"
                      style={{ minWidth: "200px" }}
                    >
                      <div>
                        <h4>Mission Order: </h4>
                        <h3>{item?.order}</h3>
                      </div>
                    </div>
                    <div
                      className="greyNumberBox"
                      style={{ minWidth: "200px" }}
                    >
                      <div>
                        <h4>Mission Status: </h4>
                        <h3>{item?.status}</h3>
                      </div>
                    </div>
                    {/* <div
                      className="greyNumberBox"
                      style={{ minWidth: "200px" }}
                    >
                      <div>
                        <h4>Date / Time: </h4>
                        <h3>{item?.dateTime}</h3>
                      </div>
                    </div> */}
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <PrimaryButton
                      classBtn={"center"}
                      text={"see more"}
                      style={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        color: "var(--text-secondary)",
                        backgroundColor: "var(--primary)",
                        height: "35px",
                        padding: "0 10px",
                        minWidth: "100px",
                        textWrap: "noWrap",
                        marginTop: "15px",
                      }}
                      click={() => navigate(`/missions/${item?.id}`)}
                    />
                    {/* <DeleteForm
                      setGo={setGoMission}
                      title={"Are you sure to delete this mission ?"}
                      smallButton
                    /> */}
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <div>
              <h4 style={{ textAlign: "center" }}>No Missions Added Yet..</h4>
            </div>
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h2 style={{ margin: "" }}>Report Attributes:</h2>
            {category?.hasReport ? (
              <DeleteForm
                setGo={setGo}
                text={"Delete Report Attributes"}
                title={"Are you sure to delete those report attributes ?"}
              />
            ) : (
              <PrimaryButton
                classBtn={"center"}
                text={"Add Report Attributes"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                  height: "40px",
                  padding: "0 15px",
                  minWidth: "100px",
                  textWrap: "noWrap",
                }}
                click={() => setOpen(true)}
              />
            )}
          </Box>

          {category?.hasReport ? (
            attributes && !attributesLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {attributes?.groups?.map((group, i) => (
                  <Box
                    className="greyBoxWhite"
                    sx={{ width: { xs: "100%", md: "32.8%" } }}
                  >
                    <div className="greyNumberBox">
                      <div>
                        <h4>{i + 1} - Group Name: </h4>
                        <h3>{group?.groupName}</h3>
                      </div>
                    </div>
                    {group?.attributes?.map((att, a) => (
                      <>
                        <div className="greyNumberBox">
                          <div>
                            <h3 style={{ fontWeight: "500" }}>
                              {a + 1} - {att?.attributeName}
                            </h3>
                          </div>
                        </div>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0 15px 15px",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <h4>Min Value: 1</h4>
                          <h4>Max Value: {att?.maxValue}</h4>
                        </Box>
                      </>
                    ))}
                  </Box>
                ))}
              </Box>
            ) : (
              <div className="loadingBox">
                <BarLoader color="#6a0505" />
              </div>
            )
          ) : (
            <div>
              <h4 style={{ textAlign: "center" }}>No Attributes Added Yet..</h4>
            </div>
          )}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h2 style={{ margin: "0 0 0 20px" }}>Special Awards:</h2>
            <PrimaryButton
              classBtn={"center"}
              text={"Add New Award"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "40px",
                padding: "0 15px",
                minWidth: "100px",
                textWrap: "noWrap",
              }}
              click={() => setOpenAward(true)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              flexWrap: "wrap",
              cursor: "pointer",
            }}
          >
            {awards?.map((item) => (
              <Box
                className="greyBoxWhite"
                sx={{
                  marginRight: { xs: "0", md: "20px" },
                  width: { xs: "100%", md: "auto" },
                }}
              >
                <div className="greyNumberBox">
                  <div>
                    <h4>Award's Name: </h4>
                    <h3>{item?.award}</h3>
                  </div>
                </div>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            <h2>Special Awards Ranking:</h2>
            <Autocomplete
              sx={{ minWidth: "300px" }}
              options={ranking ?? []}
              getOptionLabel={(option) => option?.specialAward}
              filterSelectedOptions
              name={"rank"}
              className="choose"
              value={selectedRank}
              onChange={(e, newValue) => setSelectedRank(newValue)}
              renderInput={(params) => (
                <TextFieldInput
                  {...params}
                  label="Ranking"
                  placeholder="Choose ranking"
                  className={`Input`}
                  styles={{ width: "100%" }}
                  name={"rank"}
                  Required={false}
                />
              )}
            />
          </Box>
          {ranking?.length && !rankingLoading ? (
            <>
              <TableContainer
                component={Paper}
                sx={{
                  marginBottom: "30px",
                }}
              >
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        align="center"
                        style={{ minWidth: "70px" }}
                      >
                        Rank
                      </StyledTableCell>
                      <StyledTableCell
                        align="start"
                        style={{ minWidth: "140px" }}
                      >
                        Team Name
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ minWidth: "70px" }}
                      >
                        Team ID
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                      >
                        Total Score
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {selectedRank ? (
                    <TableBody>
                      {ranking
                        ?.find(
                          (awa) =>
                            awa?.specialAwardId == selectedRank?.specialAwardId
                        )
                        ?.teams?.map((team, i) => (
                          <StyledTableRow key={team?.rank}>
                            <StyledTableCell align="center">
                              {i == 0 ? (
                                <MilitaryTechIcon
                                  sx={{ color: "gold", marginTop: "5px" }}
                                />
                              ) : i == 1 ? (
                                <MilitaryTechIcon
                                  sx={{ color: "silver", marginTop: "5px" }}
                                />
                              ) : i == 2 ? (
                                <MilitaryTechIcon
                                  sx={{ color: "#CD7F32", marginTop: "5px" }}
                                />
                              ) : (
                                <>{team?.rank}</>
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="start" sx={{}}>
                              {team?.teamName}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {team?.teamId}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <strong>
                                {team?.score ? `${team?.score} Points` : "-"}
                              </strong>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  ) : (
                    ""
                  )}
                </Table>
              </TableContainer>
            </>
          ) : (
            <div className="loadingBox">
              <BarLoader color="#6a0505" />
            </div>
          )}
        </div>
      ) : (
        <div className="loadingBox">
          <BarLoader color="#6a0505" />
        </div>
      )}
    </div>
  );
}

export default AgeGroupDetails;
