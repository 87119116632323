import { images } from "../../../../Assets/assetsHelper";
import "./MissionDetails.css";
import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router";
import { display, margin } from "@mui/system";
import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import DeleteForm from "../../../../Components/Molecules/DeleteForm/DeleteForm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:first-child": {
    borderRadius: "5px 0 0 0",
  },
  "&:last-child": {
    borderRadius: "0 5px 0 0",
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6a0505",
    color: theme.palette.common.white,
    fontSize: "15px",
  },
}));

const columns = [
  {
    id: "teamId",
    label: "ID",
    minWidth: 50,
    align: "center",
  },
  {
    id: "name",
    label: "Team Name",
    minWidth: 100,
  },
  {
    id: "organizationName",
    label: "Organization",
    minWidth: 100,
    align: "center",
  },
  { id: "judgeName", label: "Judge", minWidth: 100, align: "center" },
  {
    id: "scoringReportId",
    label: "Scoring Report",
    minWidth: 100,
    align: "center",
  },
];

function MissionDetails({
  loading,
  mission,
  url,
  teams,
  judges,
  judgesLoading,
  setStatus,
  setGoSave,
  setGoMission,
}) {
  console.log(
    judges
      ?.map((judge) => judge?.missionJudgings?.at(0)?.completed)
      ?.flat()
      ?.every((value) => value == true)
  );
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      {/* <FormModal
        Open={Open}
        HandleClose={() => setOpen(false)}
        Form={<AddReportAttributesForm setOpen={setOpen} />}
        Title={"Add Report Attributes"}
      />
      <FormModal
        Open={OpenMission}
        HandleClose={() => setOpenMission(false)}
        Form={
          <AddMissionForm
            setOpen={setOpenMission}
            url={missionsUrl}
            setMissions={setMissions}
          />
        }
        Title={"Add New Mission"}
        CustomStyle={"small"}
      /> */}
      {mission && !loading ? (
        <div className="Cont">
          <div className="greyBox">
            <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Mission Name: </h4>
                  <h2>{mission?.name}</h2>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Mission Order: </h4>
                  <h2>{mission?.order}</h2>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Mission Status: </h4>
                  <h2>{mission?.status}</h2>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Category Name: </h4>
                  <h2>{mission?.categoryName}</h2>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Age Group Name: </h4>
                  <h2>{mission?.ageGroupName}</h2>
                </div>
              </div>
              {/* <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Date: </h4>
                  <h2>{mission?.dateTime?.split("T")?.at(0)}</h2>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Time: </h4>
                  <h2>{mission?.dateTime?.split("T")?.at(1)}</h2>
                </div>
              </div> */}
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              {mission?.status == "pending" ? (
                <DeleteForm
                  setGo={setGoMission}
                  title={"Are you sure to delete this mission ?"}
                  smallButton
                  padding={"0 15px"}
                  margin={"0 5px"}
                  text={"Delete Mission"}
                />
              ) : (
                ""
              )}
              {mission?.status == "pending" || mission?.status == "paused" ? (
                <PrimaryButton
                  classBtn={"center"}
                  text={"Start Mission"}
                  // loading={addLoading}
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "5px",
                    color: "var(--text-secondary)",
                    backgroundColor: "var(--primary)",
                    height: "35px",
                    padding: "0 15px",
                    minWidth: "100px",
                    textWrap: "noWrap",
                    margin: "0 5px",
                  }}
                  click={() => {
                    setStatus("started");
                    setGoSave(true);
                  }}
                />
              ) : (
                ""
              )}
              {mission?.status == "started" ? (
                <>
                  <PrimaryButton
                    classBtn={"center"}
                    text={"Pause Mission"}
                    // loading={addLoading}
                    style={{
                      textTransform: "capitalize",
                      borderRadius: "5px",
                      color: "var(--text-secondary)",
                      backgroundColor: "var(--primary)",
                      height: "35px",
                      padding: "0 15px",
                      minWidth: "100px",
                      textWrap: "noWrap",
                      margin: "0 5px",
                    }}
                    click={() => {
                      setStatus("paused");
                      setGoSave(true);
                    }}
                  />
                  {judges
                    ?.map((judge) => judge?.missionJudgings?.at(0)?.completed)
                    ?.flat()
                    ?.every((value) => value == true) ? (
                    <PrimaryButton
                      classBtn={"center"}
                      text={"Complete Mission"}
                      // loading={addLoading}
                      style={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        color: "var(--text-secondary)",
                        backgroundColor: "var(--primary)",
                        height: "35px",
                        padding: "0 15px",
                        minWidth: "100px",
                        textWrap: "noWrap",
                        margin: "0 5px",
                      }}
                      click={() => {
                        setStatus("completed");
                        setGoSave(true);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </Box>
          </div>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h2>Judges:</h2>
          </Box>
          {judges?.length && !judgesLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {judges?.map((item) => (
                <Box
                  className="greyBoxWhite"
                  sx={{ width: { xs: "100%", md: "32.8%" } }}
                >
                  <Box
                    sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                  >
                    <div className="greyNumberBox">
                      <div>
                        <h4>Judge Name: </h4>
                        <h3>{item?.name}</h3>
                      </div>
                    </div>
                    <div className="greyNumberBox">
                      <div>
                        <h4>Teams number: </h4>
                        <h3>{item?.missionJudgings?.at(0)?.JudgeOnTeams}</h3>
                      </div>
                    </div>
                    <div className="greyNumberBox">
                      <div>
                        <h4>Completed: </h4>
                        <h3>
                          {item?.missionJudgings?.at(0)?.completed
                            ? "Completed"
                            : "Not completed"}
                        </h3>
                      </div>
                    </div>
                    <div className="greyNumberBox">
                      <div>
                        <h4>Completion Date: </h4>
                        <h3>
                          {item?.missionJudgings?.at(0)?.completionDate ?? "-"}
                        </h3>
                      </div>
                    </div>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <PrimaryButton
                      classBtn={"center"}
                      text={"see more"}
                      style={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        color: "var(--text-secondary)",
                        backgroundColor: "var(--primary)",
                        height: "35px",
                        padding: "0 10px",
                        minWidth: "100px",
                        textWrap: "noWrap",
                        margin: "15px 5px 0",
                      }}
                      click={() => navigate(`/judges/${item?.id}`)}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <div>
              <h4 style={{ textAlign: "center" }}>No Judges Assigned Yet..</h4>
            </div>
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h2 style={{}}>Teams:</h2>
          </Box>
          <Paper
            className="tableCont"
            sx={{ width: "fit-content", minWidth: "100%" }}
          >
            {teams && !loading ? (
              <>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                            }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {teams?.map((team) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={team.id}
                          >
                            {columns.map((column) => {
                              const value = team[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ padding: "10px" }}
                                >
                                  {column.id == "scoringReportId" ? (
                                    value ? (
                                      <span
                                        onClick={() =>
                                          navigate(`/reports/${value}`)
                                        }
                                        style={{
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Show Report
                                      </span>
                                    ) : (
                                      "No Report Yet"
                                    )
                                  ) : column.id == "name" ? (
                                    <span
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        navigate(`/teams/${team.id}`);
                                      }}
                                    >
                                      {value}
                                    </span>
                                  ) : column.id == "judgeName" ? (
                                    <span
                                      style={{
                                        textDecoration: "underline",
                                        cursor: team.judgeId
                                          ? "pointer"
                                          : "auto",
                                      }}
                                      onClick={() => {
                                        if (team.judgeId)
                                          navigate(`/judges/${team.judgeId}`);
                                      }}
                                    >
                                      {value ?? "-"}
                                    </span>
                                  ) : (
                                    value ?? "-"
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {!teams?.length ? (
                    <h3 style={{ textAlign: "center", margin: "50px" }}>
                      No teams Added Yet!!
                    </h3>
                  ) : (
                    ""
                  )}
                </TableContainer>
                {/* <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={pageCount * rowsPerPage}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </>
            ) : (
              <div className="loadingBox">
                <BarLoader color="#6a0505" />
              </div>
            )}
          </Paper>
        </div>
      ) : (
        <div className="loadingBox">
          <BarLoader color="#6a0505" />
        </div>
      )}
    </div>
  );
}

export default MissionDetails;
