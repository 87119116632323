import React, { useContext, useEffect, useState } from "react";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { missionJudgingURL, missionsURL, reportsURL } from "../Hooks/URL";
import { useNavigate, useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";
import useFetchWhen from "../Hooks/useFetchWhen";
import { toast } from "react-toastify";
import JudgingReport from "../Templates/Home/Missions/JudgingReport/JudgingReport";

function JudgingReportPage() {
  const navigate = useNavigate();
  const { contextData, setContextData } = useContext(userContext);
  let { id, teamId } = useParams();

  const url = `${reportsURL}/${id}`;

  const [report, setReport] = useState({});
  const { data, loading, error } = useFetch(url);

  useEffect(() => {
    if (!loading && !error && data) {
      setReport(data?.data);
    }
  }, [data, loading, error]);

  return (
    <JudgingReport
      role={contextData?.role}
      url={url}
      loading={loading}
      report={report}
    />
  );
}

export default JudgingReportPage;
