import "./Categories.css";
import React, { useState } from "react";
import { BarLoader } from "react-spinners";
import { images } from "../../../Assets/assetsHelper";
import { Box } from "@mui/material";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import axios from "axios";
import { BaseURL } from "../../../Hooks/URL";

function Categories({ navigate, loading, categories }) {
  return (
    <div className="mainContainer">
      <div className="cont">
        <div style={{ width: "100%", textAlign: "center" }}>
          <img
            alt="Logo"
            crossOrigin={"anonymous"}
            style={{ width: "200px" }}
            src={images.Company_Logo}
          />
          <h2
            style={{
              fontSize: "45px",
              margin: "0 0 10px 0",
              textAlign: "center",
            }}
          >
            Welcome To Scoring System
          </h2>
          <PrimaryButton
            classBtn={"center editButton"}
            click={() =>
              axios({
                url: `${BaseURL}/dashboard/ranking/report`,
                method: "GET",
                responseType: "blob", // important
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/app.js",
                },
                withCredentials: true,
              }).then((response) => {
                const href = URL.createObjectURL(response.data);
                console.log(response?.headers["file-name"]);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", response?.headers["file-name"]); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
              })
            }
            text={"Download Ranking Excel"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--text-primary)",
              height: "40px",
              padding: "0 30px",
              margin: "0 0 0 10px",
            }}
          />
          <PrimaryButton
            classBtn={"center editButton"}
            click={() =>
              axios({
                url: `${BaseURL}/dashboard/ranking/specialAwards`,
                method: "GET",
                responseType: "blob", // important
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/app.js",
                },
                withCredentials: true,
              }).then((response) => {
                const href = URL.createObjectURL(response.data);
                console.log(response?.headers["file-name"]);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", response?.headers["file-name"]); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
              })
            }
            text={"Download Special Awards Excel"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--text-primary)",
              height: "40px",
              padding: "0 30px",
              margin: "0 0 0 10px",
            }}
          />
        </div>
        {categories && !loading ? (
          <>
            <h3
              style={{
                fontSize: "25px",
                margin: "0 0 10px 0",
                textAlign: "start",
              }}
            >
              Our Categories:
            </h3>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                flexWrap: { xs: "wrap", md: "noWrap" },
                marginTop: "20px",
                justifyContent: "space-between",
              }}
            >
              {categories.map((category) => (
                <>
                  <Box
                    sx={{
                      width: "fit-content",
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "10px",
                      width: { xs: "48%", md: "100%" },
                      fontSize: "23px",
                      fontWeight: "700",
                      textAlign: "center",
                      // cursor: "pointer",
                      "&:hover": {
                        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
                      },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {category?.name}
                    <PrimaryButton
                      classBtn={"center"}
                      text={"see more"}
                      style={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        color: "var(--text-secondary)",
                        backgroundColor: "var(--primary)",
                        height: "35px",
                        padding: "0 10px",
                        minWidth: "100px",
                        textWrap: "noWrap",
                        marginTop: "15px",
                      }}
                      click={() => navigate(`/categories/${category.id}`)}
                    />
                  </Box>
                </>
              ))}
            </Box>
          </>
        ) : (
          <div className="loadingBox">
            <BarLoader color="#6a0505" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Categories;
