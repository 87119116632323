import React, { useContext, useEffect, useState } from "react";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import {
  ageGroupsURL,
  categoriesURL,
  classesURL,
  generateReportsURL,
  missionsURL,
  schoolURL,
} from "../Hooks/URL";
import { useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";
import AgeGroupDetails from "../Templates/Home/Categories/AgeGroupDetails/AgeGroupDetails";
import useFetchWhen from "../Hooks/useFetchWhen";

function AgeGroupDetailsPage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id, categoryId } = useParams();

  const url = `${ageGroupsURL}/${id}`;
  const reportsUrl = `${ageGroupsURL}/${id}/report`;
  const missionsUrl = `${ageGroupsURL}/${id}/missions`;
  const rankingUrl = `${ageGroupsURL}/${id}/specialAwards/ranking`;
  const awardsUrl = `${ageGroupsURL}/${id}/specialAwards`;
  const finalReportsUrl = `${generateReportsURL}/${id}`;

  const [category, setCategory] = useState({});
  const [attributes, setAttributes] = useState({});
  const [missions, setMissions] = useState({});
  const [awards, setAwards] = useState([]);
  const [ranking, setRanking] = useState({});
  const [Open, setOpen] = useState(false);
  const [OpenAward, setOpenAward] = useState(false);
  const [OpenMission, setOpenMission] = useState(false);
  const [OpenGenetare, setOpenGenerate] = useState(false);
  const [selectedRank, setSelectedRank] = useState(null);

  const [goSave, setGoSave] = useState(false);
  const { saveState, loading: addLoading } = useSave({
    url: finalReportsUrl,
    goSave,
    setGoSave,
    setNewData: {
      function: setCategory,
    },
    FetchUrl: url,
  });

  useEffect(() => {
    if (saveState.dataRes.status == "success") {
      setOpenGenerate(true);
    }
  }, [saveState]);

  const { data, loading, error } = useFetch(url);
  const {
    data: missionsData,
    loading: missionsLoading,
    error: missionsError,
  } = useFetch(missionsUrl);
  const {
    data: rankingData,
    loading: rankingLoading,
    error: rankingError,
  } = useFetch(rankingUrl);
  const {
    data: attributesData,
    loading: attributesLoading,
    error: attributesError,
  } = useFetchWhen(reportsUrl, category?.hasReport ?? false);
  const {
    data: awardsData,
    loading: awardsLoading,
    error: awardsError,
  } = useFetch(awardsUrl);

  const [go, setGo] = useState(false);
  const { deleteRes } = useDelete(reportsUrl, go, null, null, null, {}, true);
  const [goMission, setGoMission] = useState(false);
  const { deleteResMission } = useDelete(
    `${missionsURL}/${id}`,
    goMission,
    null,
    {
      function: setMissions,
    }
  );

  useEffect(() => {
    if (!awardsLoading && !awardsError && awardsData) {
      setAwards(awardsData.data);
    }
  }, [awardsData, awardsError, awardsLoading]);

  useEffect(() => {
    if (!loading && !error && data) {
      setCategory(data?.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!missionsLoading && !missionsError && missionsData) {
      setMissions(missionsData?.data);
    }
  }, [missionsData, missionsLoading, missionsError]);

  useEffect(() => {
    if (!rankingLoading && !rankingError && rankingData) {
      setRanking(rankingData?.data);
    }
  }, [rankingData, rankingLoading, rankingError]);

  useEffect(() => {
    if (!attributesLoading && !attributesError && attributesData) {
      setAttributes(attributesData?.data);
    }
  }, [attributesData, attributesLoading, attributesError]);

  return (
    <AgeGroupDetails
      category={category}
      attributes={attributes}
      missions={missions}
      role={contextData?.role}
      url={url}
      loading={loading}
      attributesLoading={attributesLoading}
      setGo={setGo}
      setGoMission={setGoMission}
      Open={Open}
      setOpen={setOpen}
      OpenAward={OpenAward}
      setOpenAward={setOpenAward}
      OpenMission={OpenMission}
      setOpenMission={setOpenMission}
      setMissions={setMissions}
      missionsUrl={missionsUrl}
      rankingLoading={rankingLoading}
      ranking={ranking}
      setSelectedRank={setSelectedRank}
      selectedRank={selectedRank}
      awards={awards}
      setAwards={setAwards}
      setGoSave={setGoSave}
      OpenGenetare={OpenGenetare}
      setOpenGenerate={setOpenGenerate}
      addLoading={addLoading}
    />
  );
}

export default AgeGroupDetailsPage;
