export const BaseURL = `https://stagingscoringapi.codechallenge-championship.org`;

export const loginURL = `${BaseURL}/dashboard/login`;
export const logoutURL = `${BaseURL}/dashboard/logout`;
export const userInfo = `${BaseURL}/dashboard/loggedInInfo`;
export const categoriesURL = `${BaseURL}/dashboard/categories`;
export const ageGroupsURL = `${BaseURL}/dashboard/ageGroups`;
export const teamsURL = `${BaseURL}/dashboard/teams`;
export const missionsURL = `${BaseURL}/dashboard/missions`;
export const judgesURL = `${BaseURL}/dashboard/judges`;
export const missionJudgingURL = `${BaseURL}/dashboard/missionJudgings`;
export const reportsURL = `${BaseURL}/dashboard/scoringReports`;
export const generateReportsURL = `${BaseURL}/dashboard/finalReports/ageGroup`;
export const activateURL = `${BaseURL}/activate`;
