import "./Judges.css";
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../Components/Molecules/FormModal/FormModal";
import { PulseLoader } from "react-spinners";
import { BaseURL } from "../../../Hooks/URL";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TextFieldInput from "../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import { styled } from "@mui/material/styles";
import { images } from "../../../Assets/assetsHelper";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Filter from "../../../Components/Molecules/Filter/Filter";
import { Box, Grid, Pagination, Typography } from "@mui/material";
import { BarLoader } from "react-spinners";
import AddJudgeForm from "../../../Components/Organisms/Forms/AddJudgeForm/AddJudgeForm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:first-child": {
    borderRadius: "5px 0 0 0",
  },
  "&:last-child": {
    borderRadius: "0 5px 0 0",
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6a0505",
    color: theme.palette.common.white,
    fontSize: "15px",
  },
}));

const columns = [
  { id: "id", label: "ID", minWidth: 100 },
  { id: "name", label: "Name", minWidth: 150 },
  {
    id: "email",
    label: "Email Address",
    minWidth: 170,
  },
  {
    id: "contactNumber",
    label: "Phone Number",
    minWidth: 170,
  },
];

function Judges({
  role,
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  searchData,
  search,
  setSearchData,
  setSearch,
  judges,
  navigate,
  handleChangePage,
  Open,
  setOpen,
  loading,
  data,
  error,
  pageCount,
  setJudges,
  addLoading,
  setActivateClicked,
  activateClicked,
  setFilter,
}) {
  return (
    <div className="mainContainer">
      <div className="cont">
        <FormModal
          Open={Open}
          HandleClose={() => {
            setOpen(false);
          }}
          Form={
            <AddJudgeForm Open={Open} setOpen={setOpen} setJudges={setJudges} />
          }
          Title={"Add New Judge"}
        />
        <div className="header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextFieldInput
              searchIcon={true}
              Required={false}
              type="text"
              placeholder={"Search"}
              className="SearchBar"
              name={"search"}
              change={(e) => setSearchData(e.target.value)}
              newValue={searchData}
              searchAction={() => {
                if (search != searchData) {
                  setSearch(searchData);
                }
              }}
              keyDown={(e) => {
                if (e.keyCode === 13)
                  if (search != searchData) {
                    setSearch(searchData);
                  }
              }}
            />
            {/* <Filter Gender schools activated setFilter={setFilter} /> */}
          </div>
          <PrimaryButton
            classBtn={"center addButton"}
            text={"Add New Judge"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--primary)",
              height: "45px",
              padding: "0 30px",
              minWidth: "100px",
              textWrap: "noWrap",
            }}
            click={() => {
              setOpen(true);
            }}
          />
        </div>
        <Paper
          className="tableCont"
          sx={{ width: "fit-content", minWidth: "100%" }}
        >
          {judges && !loading ? (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                          }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {judges.map((judge) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={judge.id}
                          onClick={() => {
                            navigate(`/judges/${judge.id}`);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {columns.map((column) => {
                            const value = judge[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ padding: "10px" }}
                              >
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!judges?.length ? (
                  <h3 style={{ textAlign: "center", margin: "50px" }}>
                    No Judges Added Yet!!
                  </h3>
                ) : (
                  ""
                )}
              </TableContainer>
              {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={pageCount * rowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </>
          ) : (
            <div className="loadingBox">
              <BarLoader color="#6a0505" />
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}

export default Judges;
