import axios from "axios";
import { useEffect, useReducer, useState } from "react";
import useFetch from "./useFetch";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Fetch } from "./Fetch";

const initialState = {
  dataRes: [],
  loadingRes: true,
  errorRes: null,
};

const ACTION = {
  API_REQUEST: "api-request",
  FETCH_DATA: "fetch-data",
  ERROR: "error",
};

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTION.API_REQUEST:
      return { ...state, loadingRes: true, errorRes: null };
    case ACTION.FETCH_DATA:
      return { ...state, dataRes: payload, loadingRes: false };
    case ACTION.ERROR:
      return { ...state, loadingRes: false, errorRes: payload };
    default:
      return state;
  }
}
function useEdit(
  url,
  goEdit,
  setGoEdit,
  newObject,
  navigateAfter,
  isImage,
  setNewData,
  FetchUrl
) {
  const navigate = useNavigate();
  const [cookies] = useCookies(["TOKEN"]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [dataEdited, setDataEdited] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (goEdit) {
      setLoading(true);
      dispatch({ type: ACTION.API_REQUEST });
      axios
        .patch(url, newObject, {
          headers: {
            "Content-Type": isImage
              ? "multipart/form-data"
              : "application/json",
            Accept: "application/app.js",
            // Authorization: `Bearer ${cookies.TOKEN}`,
          },
          withCredentials: true,
        })
        .then((res) => {
          dispatch({ type: ACTION.FETCH_DATA, payload: res?.data });
          setLoading(false);
          toast.success(res?.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
          if (navigateAfter) {
            navigate(navigateAfter);
          }
          Fetch(FetchUrl ?? url, setNewData, navigate);
        })
        .catch((err) => {
          dispatch({ type: ACTION.ERROR, payload: err.error });
          setLoading(false);
          if (err.response?.data.error.errors) {
            err.response?.data?.error?.errors.forEach((e) => {
              toast.error(e.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
              });
            });
          } else if (err.response?.data.error) {
            toast.error(err.response?.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
      setGoEdit(false);
      setDataEdited(false);
    }
  }, [goEdit]);
  // const fetchNewData = useFetch(url, null, null, null, dataEdited);
  return { state, loading };
}

export default useEdit;
