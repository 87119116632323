import React, { useContext, useEffect, useState } from "react";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { missionsURL } from "../Hooks/URL";
import { useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";
import AgeGroupDetails from "../Templates/Home/Categories/AgeGroupDetails/AgeGroupDetails";
import useFetchWhen from "../Hooks/useFetchWhen";
import Missions from "../Templates/Home/Missions/MissionDetails/MissionDetails";
import MissionDetails from "../Templates/Home/Missions/MissionDetails/MissionDetails";

function MissionDetailsPage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id, categoryId } = useParams();

  const url = `${missionsURL}/${id}`;
  const teamsUrl = `${missionsURL}/${id}/teams`;
  const judgesUrl = `${missionsURL}/${id}/judges`;

  const [mission, setMission] = useState({});
  const [teams, setTeams] = useState([]);
  const [judges, setJudges] = useState([]);
  const { data, loading, error } = useFetch(url);
  const {
    data: teamsData,
    loading: teamsLoading,
    error: teamsError,
  } = useFetch(teamsUrl);
  const {
    data: judgesData,
    loading: judgesLoading,
    error: judgesError,
  } = useFetch(judgesUrl);

  useEffect(() => {
    if (!loading && !error && data) {
      setMission(data?.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!teamsLoading && !teamsError && teamsData) {
      setTeams(teamsData?.data);
    }
  }, [teamsData, teamsLoading, teamsError]);

  useEffect(() => {
    if (!judgesError && !judgesLoading && judgesData) {
      setJudges(judgesData?.data);
    }
  }, [judgesData, judgesLoading, judgesError]);

  const [goSave, setGoSave] = useState(false);
  const [newStatus, setStatus] = useState("");
  const { saveState, loading: addLoading } = useSave({
    url: `${url}/changeStatus`,
    goSave,
    setGoSave,
    newObject: {
      status: newStatus,
    },
    refreshAfter: true,
  });

  const [goMission, setGoMission] = useState(false);
  const { deleteResMission } = useDelete(
    `${missionsURL}/${id}`,
    goMission,
    `categories/${categoryId}/ageGroups/${mission?.ageGroupId}`
  );

  return (
    <MissionDetails
      mission={mission}
      role={contextData?.role}
      url={url}
      loading={loading}
      teams={teams}
      judges={judges}
      judgesLoading={judgesLoading}
      setStatus={setStatus}
      setGoSave={setGoSave}
      setGoMission={setGoMission}
    />
  );
}

export default MissionDetailsPage;
