import React, { useContext, useEffect, useState } from "react";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { categoriesURL, classesURL, schoolURL } from "../Hooks/URL";
import { useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";
import CategoryDetails from "../Templates/Home/Categories/CategoryDetails/CategoryDetails";

function CategoryDetailsPage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id } = useParams();

  const categoryUrl = `${categoriesURL}/${id}`;
  const awardsUrl = `${categoriesURL}/${id}/specialAwards`;

  const [Open, setOpen] = useState(false);
  const [category, setCategory] = useState({});
  // const [awards, setAwards] = useState([]);
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useFetch(categoryUrl);
  // const {
  //   data: awardsData,
  //   loading: awardsLoading,
  //   error: awardsError,
  // } = useFetch(awardsUrl);

  // useEffect(() => {
  //   if (!awardsLoading && !awardsError && awardsData) {
  //     setAwards(awardsData.data);
  //   }
  // }, [awardsData, awardsError, awardsLoading]);

  useEffect(() => {
    if (!categoryLoading && !categoryError && categoryData) {
      setCategory(categoryData.data);
    }
  }, [categoryData, categoryLoading, categoryError]);

  return (
    <CategoryDetails
      category={category}
      ageGroups={category?.ageGroups}
      role={contextData?.role}
      url={categoryUrl}
      loading={categoryLoading}
      // awards={awards}
      setOpen={setOpen}
      Open={Open}
      // setAwards={setAwards}
    />
  );
}

export default CategoryDetailsPage;
