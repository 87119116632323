import React, { useContext, useEffect, useState } from "react";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";
import JudgeProfile from "../Templates/Home/Judges/JudgeProfile/JudgeProfile";
import { judgesURL, missionJudgingURL } from "../Hooks/URL";
import useFetchWhen from "../Hooks/useFetchWhen";

function JudgeProfilePage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id } = useParams();

  const url = `${judgesURL}/${id}`;
  const missionsUrl = `${judgesURL}/${id}/missions`;
  const page = "judges";

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [judge, setJudge] = useState({});
  const [missions, setMissions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [Open, setOpen] = useState(false);
  const [OpenMission, setOpenMission] = useState(false);
  const { data, loading, error } = useFetch(url);
  const [goTeam, setGoTeam] = useState("");
  const {
    data: missionsData,
    loading: missionsLoading,
    error: missionsError,
  } = useFetch(missionsUrl);
  const {
    data: teamsData,
    loading: teamsLoading,
    error: teamsError,
  } = useFetchWhen(
    `${missionJudgingURL}/${
      missions?.find((item, i) => i == value)?.missionJudgingId
    }/teams`,
    missions?.find((item, i) => i == value)?.missionJudgingId || goTeam
  );

  const [go, setGo] = useState(false);
  const { deleteRes } = useDelete(url, go, page);

  const [goMission, setGoMission] = useState(false);
  const { deleteRes: deleteMission } = useDelete(
    `${missionJudgingURL}/${
      missions?.find((item, i) => i == value)?.missionJudgingId
    }`,
    goMission,
    null,
    null,
    null,
    {},
    true
  );

  const [deletedTeam, setDeletedTeam] = useState("");
  const { deleteRes: deleteTeam } = useDelete(
    `${missionJudgingURL}/${
      missions?.find((item, i) => i == value)?.missionJudgingId
    }/teams/${deletedTeam}`,
    goTeam,
    null,
    {
      function: setTeams,
    },
    `${missionJudgingURL}/${
      missions?.find((item, i) => i == value)?.missionJudgingId
    }/teams`,
    {},
    false,
    setGoTeam
  );

  const [goSave, setGoSave] = useState(false);

  useEffect(() => {
    if (!loading && !error && data) {
      setJudge(data.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!missionsLoading && !missionsError && missionsData) {
      setMissions(missionsData?.data);
    }
  }, [missionsData, missionsLoading, missionsError]);

  useEffect(() => {
    if (!teamsLoading && !teamsError && teamsData) {
      setTeams(teamsData?.data);
    }
  }, [teamsData, teamsLoading, teamsError]);

  useEffect(() => {
    setContextData({
      ...contextData,
      page: "judges",
    });
  }, [judge]);

  return (
    <JudgeProfile
      teams={teams}
      setTeams={setTeams}
      teamsLoading={teamsLoading}
      value={value}
      handleChange={handleChange}
      Open={Open}
      setOpen={setOpen}
      judge={judge}
      setJudge={setJudge}
      url={url}
      setGo={setGo}
      setGoMission={setGoMission}
      loading={loading}
      role={contextData?.role}
      setGoSave={setGoSave}
      OpenMission={OpenMission}
      setOpenMission={setOpenMission}
      setMissions={setMissions}
      missionsUrl={missionsUrl}
      missions={missions}
      setGoTeam={setGoTeam}
      setDeletedTeam={setDeletedTeam}
    />
  );
}

export default JudgeProfilePage;
