import React, { useEffect, useState } from "react";
import "./AssignTeamToJudge.css";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { Autocomplete, Box } from "@mui/material";
import useSave from "../../../../Hooks/useSave";
import { judgesURL, missionJudgingURL } from "../../../../Hooks/URL";
import { Formik } from "formik";
import useFetch from "../../../../Hooks/useFetch";

function AssignTeamToJudge({
  judge,
  url,
  setOpen,
  setTeams,
  missionJudgingId,
}) {
  const [newObject, setNewObject] = useState({});
  const [teamsSelected, setTeamsSelected] = useState([]);
  const [error, setError] = useState("");
  const [goSave, setGoSave] = useState(false);
  const { saveState, loading: addLoading } = useSave({
    url: `${missionJudgingURL}/${missionJudgingId}/teams`,
    goSave,
    setGoSave,
    newObject,
    setNewData: {
      function: setTeams,
    },
  });
  const {
    data: teamsList,
    loading: teamsLoading,
    error: teamsError,
  } = useFetch(`${missionJudgingURL}/${missionJudgingId}/teams/toAssign`);

  useEffect(() => {
    if (saveState.dataRes.status == "success") {
      setOpen(false);
    }
  }, [saveState]);

  useEffect(() => {
    if (teamsSelected.length) {
      setError("");
    }
  }, [teamsSelected]);

  return (
    <div className="CourseSmallFormWrapper">
      <Formik
        initialValues={{
          teamsSelected: [],
        }}
        onSubmit={(values) => {
          if (teamsSelected.length) {
            setNewObject({ teamsIds: teamsSelected?.map((item) => item?.id) });
            setGoSave(true);
          } else {
            setError("Add at least one team !!");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="ClassFormContainer">
              <br />

              <Autocomplete
                multiple
                options={teamsList?.data ?? []}
                getOptionLabel={(option) =>
                  `${option?.teamId} - ${option?.name} - ${
                    option?.JudingTeamStatus == 0
                      ? "With other judge"
                      : option?.JudingTeamStatus == 1
                      ? "With this Judge"
                      : "Free"
                  }`
                }
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <Box
                      key={key}
                      component="li"
                      sx={{
                        "& > img": { mr: 2, flexShrink: 0 },
                        backgroundColor:
                          option?.JudingTeamStatus == 0
                            ? "#6a050515"
                            : option?.JudingTeamStatus == 1
                            ? "#00000015"
                            : "white",
                      }}
                      {...optionProps}
                    >
                      {option?.teamId} - {option.name} -{" "}
                      {option?.JudingTeamStatus == 0
                        ? "(With other judge)"
                        : option?.JudingTeamStatus == 1
                        ? "(With this Judge)"
                        : "(Without Judge)"}
                    </Box>
                  );
                }}
                filterSelectedOptions
                name={"teams"}
                className="choose"
                value={teamsSelected}
                onChange={(e, newValue) => {
                  setTeamsSelected(newValue);
                }}
                getOptionDisabled={(option) =>
                  option?.JudingTeamStatus == 1 || option?.JudingTeamStatus == 0
                }
                renderInput={(params) => (
                  <TextFieldInput
                    {...params}
                    label="Add Teams"
                    placeholder="Teams"
                    className={`Input`}
                    styles={{ width: "100%" }}
                    name={"teams"}
                    Required={false}
                  />
                )}
              />
              {error && <p className="error">{error}</p>}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={"Add"}
                loading={addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AssignTeamToJudge;
